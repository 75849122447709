import palette from './palette';

const theme = {
  fontFamily: '"Roboto", sans-serif',
  fontFamilySerif: '"Noe Text", "PT Serif", serif',
  fontWeightMedium: '500',
  fontWeightNormal: '300',
  colours: palette,
  borderRadius: '4px',
  boxShadow: {
    smallHover: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    large: '0px 2px 20px rgba(0, 0, 0, 0.1)',
    largeHover: '0px 10px 20px rgba(0, 0, 0, 0.2)',
  },
  easing: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
};

export type ThemeColour = keyof typeof palette;

export type Theme = typeof theme;

export default theme;
