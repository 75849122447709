import styled from 'styled-components';
import React, { FC, FormEventHandler, useEffect, useState } from 'react';
import theme from '../utils/theme';
import PrimaryButton from './Button';

export const VoidTestBookingForm: FC<{ authToken: string }> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [clientMnemonics, setClientMnemonics] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [result, setResult] = useState<string | null>(null);

  useEffect(() => {
    if (clientMnemonics.length > 0) return;

    fetch(`${process.env.PRIVATE_API_URL}/available-hotels`, {
      headers: {
        Authorization: 'Bearer ' + props.authToken,
      },
      mode: 'cors',
    }).then((response) => {
      if (response.status !== 200) {
        response.text().then((body) => setError(body.trim().length > 0 ? body : 'Failed to fetch client names'));
      } else {
        response.json().then((result) => setClientMnemonics(result));
      }
    });
  }, [clientMnemonics]);

  const handleFormSubmission: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    const payload = new URLSearchParams(new FormData(form) as any);

    setClientMnemonics([]);
    setIsLoading(true);
    setError(null);
    setResult(null);

    fetch(form.action, {
      method: 'post',
      body: payload,
      headers: {
        Authorization: 'Bearer ' + props.authToken,
      },
    })
      .then((response) => {
        setIsLoading(false);
        if (response.status !== 200) {
          response.text().then((body) => setError(body.trim().length > 0 ? body : 'Failed to void test booking'));
        } else {
          setResult('Success');
          response.json().then((result) => setClientMnemonics(result));
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e instanceof Error ? e.message : e.toString());
      });
  };

  return (
    <>
      <h3>Void test booking</h3>
      <form method="POST" action={`${process.env.PRIVATE_API_URL}/void-test-bookings`} onSubmit={handleFormSubmission}>
        <p>
          <Label htmlFor="client-mnemonic">Client Name:</Label>
          <select name="client-mnemonic" required={true}>
            {clientMnemonics.sort().map((mnemonic) => (
              <option key={mnemonic} value={mnemonic}>
                {mnemonic}
              </option>
            ))}
          </select>
        </p>
        <p>
          <Label htmlFor="booking-reference">Booking reference:</Label>
          <Input type="string" name="booking-reference" required={true} />
        </p>
        <PrimaryButton disabled={isLoading} isLoading={isLoading} type="submit">
          Void test booking
        </PrimaryButton>

        {result !== null && <p style={{ color: theme.colours.confirmation400 }}>{result}</p>}
        {error !== null && <p style={{ color: theme.colours.alert400 }}>{error}</p>}
      </form>
    </>
  );
};

const Label = styled.label`
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
  font-weight: ${theme.fontWeightMedium};
  min-width: 200px;
`;
const Input = styled.input`
  height: 44px;
  color: ${theme.colours.black};
  top: 25px;
  left: 12px;
  line-height: 20px;
  padding-left: 12px;
`;
