export default {
  primary800: 'hsl(253,91%,21%)',
  primary700: 'hsl(253,61%,31%)',
  primary600: 'hsl(253,56%,41%)',
  primary500: 'hsl(253,51%,51%)',
  primary400: 'hsl(252,56%,61%)',
  primary300: 'hsl(253,61%,71%)',
  primary200: 'hsl(252,71%,81%)',
  primary100: 'hsl(252,91%,91%)',
  secondary700: 'hsl(20,92%,35%)',
  secondary600: 'hsl(21,87%,45%)',
  secondary550: 'hsl(20,84%,55%)',
  secondary500: 'hsl(20,95%,67%)',
  secondary400: 'hsl(20,84%,65%)',
  secondary300: 'hsl(20,87%,75%)',
  secondary200: 'hsl(20,92%,85%)',
  secondary100: 'hsl(20,98%,95%)',
  black: 'hsl(225,18%,13%)',
  gray900: 'hsl(0,0%,17%)',
  gray800: 'hsl(0,0%,27%)',
  gray700: 'hsl(0,0%,37%)',
  gray600: 'hsl(0,0%,47%)',
  gray500: 'hsl(0,0%,57%)',
  gray400: 'hsl(0,0%,67%)',
  gray300: 'hsl(0,0%,77%)',
  gray200: 'hsl(0,0%,87%)',
  gray150: 'hsl(0,0%,93%)',
  gray100: 'hsl(0,0%,97%)',
  white: 'hsl(0,0%,100%)',
  confirmation800: 'hsl(123,90%,19%)',
  confirmation700: 'hsl(122,70%,29%)',
  confirmation600: 'hsl(122,50%,39%)',
  confirmation500: 'hsl(122,40%,49%)',
  confirmation400: 'hsl(122,40%,59%)',
  confirmation300: 'hsl(122,50%,69%)',
  confirmation200: 'hsl(123,70%,79%)',
  confirmation100: 'hsl(123,90%,89%)',
  alert800: 'hsl(4,90%,22%)',
  alert700: 'hsl(4,77%,32%)',
  alert600: 'hsl(4,70%,42%)',
  alert500: 'hsl(4,67%,52%)',
  alert400: 'hsl(4,67%,62%)',
  alert300: 'hsl(4,70%,72%)',
  alert200: 'hsl(4,77%,82%)',
  alert100: 'hsl(4,90%,92%)',
  information800: 'hsl(44,100%,48%)',
  information700: 'hsl(44,100%,28%)',
  information600: 'hsl(44,90%,38%)',
  information500: 'hsl(44,85%,48%)',
  information400: 'hsl(44,83%,58%)',
  information300: 'hsl(44,85%,68%)',
  information200: 'hsl(44,90%,78%)',
  information100: 'hsl(44,100%,88%)',
};
