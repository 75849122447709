import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import LoadingSpinner from './LoadingSpinner';
import theme from '../utils/theme';

export type ButtonProps = {
  isLoading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
} & HTMLAttributes<HTMLButtonElement>;

const Container = styled.button<ButtonProps>`
  appearance: none;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  height: 46px;
  font-size: 14px !important;
  font-weight: ${theme.fontWeightMedium};
  line-height: 1em !important;
  padding: 14px 15px;
  cursor: ${({ isLoading }: any) => (isLoading ? 'progress' : 'pointer')};
  border-radius: ${theme.borderRadius};
  border: none;
  outline: none;
  vertical-align: middle;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  min-width: 100px;
  background-color: transparent;

  &[disabled] {
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }
`;

const HideWhenLoading = styled.span<{ isLoading?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.isLoading ? 0 : 1)};
`;

export const Button = ({ children, ...props }: ButtonProps) => (
  <Container {...props}>
    <HideWhenLoading isLoading={props.isLoading}>{children}</HideWhenLoading>
    {props.isLoading ? <LoadingSpinner /> : null}
  </Container>
);

export const ButtonSmall = styled(Button)`
  padding: 10px 12px;
  height: 36px;
`;

export const PrimaryButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${theme.colours.primary500};
  color: ${theme.colours.white};

  &:hover {
    background-color: ${({ isLoading }) => (isLoading ? theme.colours.primary500 : theme.colours.primary600)}};
  }

  &:disabled {
    background-color: ${theme.colours.gray300};
  }
`;

export const PrimaryButtonSmall = styled(PrimaryButton)`
  padding: 10px 12px;
  height: 36px;
`;

export const SecondaryButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${theme.colours.white};
  color: ${theme.colours.primary500};
  border: 1px ${theme.colours.gray300} solid;

  &:hover {
    color: ${theme.colours.primary600};
    box-shadow: ${({ isLoading }) => (isLoading ? 'none' : theme.boxShadow.smallHover)};
  }

  &:disabled {
    color: ${theme.colours.gray300};
    box-shadow: none;
  }
`;

export const SecondaryButtonSmall = styled(SecondaryButton)`
  padding: 10px 12px;
  height: 36px;
`;

export const LinkButton = styled(Button)`
  color: ${theme.colours.primary500};

  &:hover {
    color: ${theme.colours.primary600};
  }

  &:disabled {
    color: ${theme.colours.gray300};
  }
`;

export const LinkButtonSmall = styled(LinkButton)`
  padding: 10px 12px;
  height: 36px;
`;

export default PrimaryButton;
