import styled from 'styled-components';
import React, { FC, FormEventHandler, useState } from 'react';
import { SetInitialCpcBidResponse } from '@paid-search/private-api/src/maxCpc/ResultWithoutCalculationResponse';
import theme from '../utils/theme';
import PrimaryButton from './Button';

export const CPCForm: FC<{ authToken: string }> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState<SetInitialCpcBidResponse>([]);
  const [error, setError] = useState<string | null>(null);

  const handleFormSubmission: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    const payload = new URLSearchParams(new FormData(form) as any);

    setCampaigns([]);
    setIsLoading(true);
    setError(null);

    fetch(form.action, {
      method: 'post',
      body: payload,
      headers: {
        Authorization: 'Bearer ' + props.authToken,
      },
    })
      .then((response) => {
        setIsLoading(false);
        if (response.status !== 200) {
          response.text().then((body) => setError(body.trim().length > 0 ? body : 'Failed to set the initial CPC'));
        } else {
          response.json().then((result) => setCampaigns(result));
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e instanceof Error ? e.message : e.toString());
      });
  };

  return (
    <>
      <h3>Set initial bid CPC</h3>
      <form method="POST" action={`${process.env.PRIVATE_API_URL}/set-initial-bid-cpc`} onSubmit={handleFormSubmission}>
        <p>
          <Label htmlFor="account-id">Account ID (e.g. 123-456-7890):</Label>
          <Input name="account-id" required={true} />
        </p>
        <p>
          <Label htmlFor="target-roas">Target ROAS (e.g. 14):</Label>
          <Input type="number" name="target-roas" required={true} defaultValue={14} />
        </p>
        <p>
          <Label htmlFor="commission-rate">Commission Rate (e.g. 0.14):</Label>
          <Input type="number" name="commission-rate" min="0.01" max="0.25" step="0.01" required={true} defaultValue={0.14} />
        </p>
        <p>
          <Label htmlFor="is-dry-run">Dry run?:</Label>
          <Checkbox type="checkbox" defaultChecked value="true" name="is-dry-run" />
        </p>
        <PrimaryButton disabled={isLoading} isLoading={isLoading} type="submit">
          Apply
        </PrimaryButton>

        {error !== null && <p style={{ color: theme.colours.alert400 }}>{error}</p>}

        {campaigns.map((campaign, index) => {
          if (campaign.result === 'ERROR' || campaign.result === 'SKIPPED_DUE_TO_MORE_THAN_ONE_HOTEL') {
            return (
              <Result>
                <ResultLabel>Result: {campaign.result}</ResultLabel>
                <hr />
              </Result>
            );
          }

          function formatAsPercentage(value: number, precision: number) {
            return <>{(value * 100).toFixed(precision)}%</>;
          }

          if (campaign.result === 'OK' || campaign.result === 'NOT_APPLIED_DUE_TO_DRY_RUN') {
            return (
              <div key={index}>
                <Result>
                  <ResultLabel>Campaign name</ResultLabel>
                  <p>{campaign.campaign.name}</p>
                </Result>
                <Result>
                  <ResultLabel>Ad groups</ResultLabel>
                  <ul>
                    {campaign.campaign.adGroups.map((adGroup) => {
                      return <li>{adGroup.name}</li>;
                    })}
                  </ul>
                </Result>
                <Result>
                  <ResultLabel>Result</ResultLabel>
                  <p>{campaign.result}</p>
                </Result>
                <Result>
                  <ResultLabel>API Key</ResultLabel>
                  <p>{campaign.inputs.apiKey}</p>
                </Result>
                <Result>
                  <ResultLabel>Commission Rate</ResultLabel>
                  <p>{formatAsPercentage(campaign.inputs.commissionRate, 0)}</p>
                </Result>
                <Result>
                  <ResultLabel>Target ROAS</ResultLabel>
                  <p>{campaign.inputs.targetRoas}</p>
                </Result>
                <Result>
                  <ResultLabel>Commission Rate Boost</ResultLabel>
                  <p>{campaign.inputs.conversionRateBoost}</p>
                </Result>
                <Result>
                  <ResultLabel>Date Range</ResultLabel>
                  <p>
                    {campaign.inputs.dateRange.startInclusive.toString()} - {campaign.inputs.dateRange.endExclusive.toString()}
                  </p>
                </Result>
                <Result>
                  <ResultLabel>Hotel CvR</ResultLabel>
                  <p>{formatAsPercentage(campaign.calculation.hotelCvr, 2)}</p>
                </Result>
                <Result>
                  <ResultLabel>Cost per click</ResultLabel>
                  <p>{formatInUsd(campaign.calculation.cpc)}</p>
                </Result>
                <Result>
                  <ResultLabel>Average booking value</ResultLabel>
                  <p>${formatInUsd(campaign.calculation.averageBookingValue)}</p>
                </Result>
                <Result>
                  <ResultLabel>Commission per booking</ResultLabel>
                  <p>${formatInUsd(campaign.calculation.commissionPerBooking)}</p>
                </Result>
                <Result>
                  <ResultLabel>Target cost per booking</ResultLabel>
                  <p>${formatInUsd(campaign.calculation.targetCostPerBooking)}</p>
                </Result>
                <hr />
              </div>
            );
          }
        })}
      </form>
    </>
  );
};

const Result = styled.div`
  display: flex;
  align-items: center;
`;

const ResultLabel = styled.label`
  width: 150px;
  font-weight: bold;
`;

function formatInUsd(cpc: number) {
  return `${cpc.toFixed(2)} USD`;
}

const Label = styled.label`
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
  font-weight: ${theme.fontWeightMedium};
`;
const Input = styled.input`
  height: 44px;
  color: ${theme.colours.black};
  top: 25px;
  left: 12px;
  line-height: 20px;
  padding-left: 12px;
`;
const Checkbox = styled.input`
  height: 20px;
  width: 20px;
`;
