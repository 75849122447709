import React, { Component, MouseEventHandler } from 'react';
import styled from 'styled-components';
import svgs, { IconName } from './svgs';

type Props = {
  icon: IconName;
  size?: number;
  color?: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  active?: boolean;
};

type ContainerProps = {
  size: number;
  color: string;
};

export const IconContainer = styled.i<ContainerProps>`
  width: ${(props) => props.size}px;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
  }
`;

export default class Icon extends Component<Props> {
  static defaultProps: Partial<Props> = {
    size: 32,
    color: 'currentColor',
  };

  calculateRatio(svg: any) {
    const viewBox = svg['defaultProps.viewBox'];

    if (viewBox) {
      const [width, height] = viewBox.split(' ').slice(2);
      return parseInt(height) / parseInt(width);
    }

    return 1;
  }

  render() {
    const { icon, size, color, className, onClick } = this.props;
    const Svg = svgs[icon] || React.Fragment;

    return (
      <IconContainer size={size!} color={color!} className={className} onClick={onClick}>
        <Svg {...{ size, color }} id={`svg-icon-${icon}`} />
      </IconContainer>
    );
  }
}
