import styled from 'styled-components';
import Icon from './Icon';

const LoadingSpinner = styled(Icon).attrs({
  icon: 'loadingSpinner',
  size: 16,
})`
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto auto;
  right: 0;
  position: absolute;
  animation: spin 1s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default LoadingSpinner;
